@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

html {
    height: 100%;
    background-color: white;
}

body {
    @apply font-sans;
    height: 100%;
    scrollbar-width: thin;
}

.background-image-curvy {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='1920' height='1080' preserveAspectRatio='none' viewBox='0 0 1920 1080'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1032%26quot%3b)' fill='none'%3e%3cpath d='M 0%2c315 C 128%2c302.8 384%2c248.6 640%2c254 C 896%2c259.4 1024%2c351.6 1280%2c342 C 1536%2c332.4 1792%2c233.2 1920%2c206L1920 1080L0 1080z' fill='rgba(252%2c 117%2c 2%2c 0)'%3e%3c/path%3e%3cpath d='M 0%2c606 C 192%2c662.6 576%2c865.2 960%2c889 C 1344%2c912.8 1728%2c757.8 1920%2c725L1920 1080L0 1080z' fill='rgba(249%2c 125%2c 33%2c 1)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1032'%3e%3crect width='1920' height='1080' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
}

::-webkit-scrollbar {
    background: transparent;
    height: 8px;
    width: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 0.25rem;
    background: #c5c5c5;
}

::-webkit-scrollbar-track {
    background: transparent;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.sidenav {
    @apply md:relative md:translate-x-0;
}